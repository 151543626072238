<template>
  <section>
    <div>
      <dialog-control v-if="!controlled && user.grade !== 'cabinet social'"></dialog-control>

      <!-- <v-dialog v-model="dialogTutorial" persistent max-width="1200px">
        <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
          <v-card-text style="background-color: #f1f4f9">
            <v-row>
              <v-col cols="12" md="3" class="no-padding ml-n2">
                <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                  <v-card-title>
                    <v-img
                      :src="require('@/assets/images/logos/anavel_logo.png')"
                      max-height="150px"
                      max-width="120px"
                      alt="logo"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-title class="text-center justify-center muller-capitalized"
                    >Mes Services Assurance</v-card-title
                  >
                  <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                  <v-card-text class="no-padding">
                    <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="9" class="no-padding d-flex">
                <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                  <v-card-title class="muller-capitalized EoleBlue--text">
                    Nous vous proposons maintenant une pré-configuration rapide de votre solution
                  </v-card-title>
                  <v-card-subtitle class="mt-5"> Configuration de l'administrateur général </v-card-subtitle>
                  <v-card-text>
                    <v-stepper v-model="stepperAccountTutorial" flat>
                      <v-stepper-items>
                        <el-steps :active="stepperTutorial" finish-status="success" simple>
                          <el-step title="Votre compte"></el-step>
                          <el-step title="Invitations"></el-step>
                          <el-step title="Étape 3"></el-step>
                        </el-steps>

                        <v-stepper-content step="0" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Informations professionnelles</span>
                          </v-card-title>
                          <v-card-text class="mt-5">
                            <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-lastname"
                                    dense
                                    prepend-inner-icon="mdi-account"
                                    outlined
                                    :rules="basicRules"
                                    :error-messages="lastnameErrorMessage"
                                    v-model="editedItem.lastname"
                                    label="Nom utilisateur"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-firstname"
                                    dense
                                    prepend-inner-icon="mdi-account"
                                    outlined
                                    :rules="basicRules"
                                    :error-messages="firstnameErrorMessage"
                                    v-model="editedItem.firstname"
                                    label="Prénom utilisateur"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    id="new-account-status"
                                    dense
                                    prepend-inner-icon="mdi-cogs"
                                    outlined
                                    value="editedItem.statut"
                                    v-model="editedItem.statut"
                                    :items="states"
                                    label="Type d'utilisateur"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    outlined
                                    dense
                                    label="Lieu de travail principal"
                                    :items="agency.etablishment"
                                    item-value="id"
                                    item-text="city"
                                    v-model="searchEtablishment"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-street"
                                    dense
                                    prepend-inner-icon="mdi-account"
                                    outlined
                                    v-model="editedItem.mainWorkplaceStreet"
                                    label="Adresse"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-autocomplete
                                    id="new-account-city"
                                    messages="Entrez un code postal"
                                    :search-input.sync="searchCityProfessionnal"
                                    :items="cityItemsProfessionnal"
                                    outlined
                                    v-model="editedItem.mainWorkplaceCity"
                                    :value="editedItem.mainWorkplaceZip"
                                    label="Ville"
                                    clearable
                                    hide-details
                                    hide-selected
                                    dense
                                    no-filter
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-title> Entrez un code postal </v-list-item-title>
                                      </v-list-item>
                                    </template>

                                    <template v-slot:item="{ item }">
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template></v-autocomplete
                                  >
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-email"
                                    dense
                                    prepend-inner-icon="mdi-gmail"
                                    outlined
                                    :rules="emailRules"
                                    :error-messages="emailErrorMessage"
                                    v-model="editedItem.email"
                                    label="Mail professionnel"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-phone"
                                    dense
                                    prepend-inner-icon="mdi-phone"
                                    outlined
                                    :rules="phoneNumberRules"
                                    :error-messages="phoneNumberErrorMessage"
                                    v-model="editedItem.phoneNumberProfessional"
                                    label="N° professionnel"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-content step="1" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Informations personnelles</span>
                          </v-card-title>

                          <v-card-text>
                            <v-form ref="form">
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    id="new-account-street"
                                    dense
                                    prepend-inner-icon="mdi-account"
                                    outlined
                                    v-model="editedItem.street"
                                    label="Adresse personnelle"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-autocomplete
                                    id="new-account-city"
                                    messages="Entrez un code postal"
                                    :search-input.sync="searchCity"
                                    :items="cityItems"
                                    outlined
                                    v-model="editedItem.city"
                                    :value="editedItem.zip"
                                    label="Ville"
                                    clearable
                                    hide-details
                                    hide-selected
                                    dense
                                    no-filter
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-title> Entrez un code postal </v-list-item-title>
                                      </v-list-item>
                                    </template>

                                    <template v-slot:item="{ item }">
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template></v-autocomplete
                                  >
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-email"
                                    dense
                                    prepend-inner-icon="mdi-gmail"
                                    outlined
                                    :rules="emailRules"
                                    :error-messages="emailErrorMessage"
                                    v-model="editedItem.personnalEmail"
                                    label="Mail personnel"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    id="new-account-phone"
                                    dense
                                    prepend-inner-icon="mdi-phone"
                                    outlined
                                    :rules="phoneNumberRules"
                                    :error-messages="phoneNumberErrorMessage"
                                    v-model="editedItem.personnalPhoneNumber"
                                    label="N° personnel"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    dense
                                    prepend-inner-icon="mdi-cogs"
                                    outlined
                                    v-model="editedItem.idMaritalStatus"
                                    :items="[
                                      {
                                        id: 1,
                                        designation: 'Célibataire',
                                      },

                                      {
                                        id: 2,
                                        designation: 'En concubinage',
                                      },
                                      {
                                        id: 3,
                                        designation: 'Marié(e)',
                                      },
                                      {
                                        id: 4,
                                        designation: 'Pacsé(e)',
                                      },
                                      {
                                        id: 5,
                                        designation: 'Divorcé(e)',
                                      },
                                      {
                                        id: 6,
                                        designation: 'Veuf(ve)',
                                      },
                                    ]"
                                    item-text="designation"
                                    item-value="id"
                                    label="Situation familiale"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" md="6" class="mt-n3">
                                  <v-switch
                                    inset
                                    v-model="editedItem.hasChildren"
                                    label="Avez-vous des enfants ?"
                                  ></v-switch>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>

                          <v-card-text
                            class="no-padding mb-10"
                            v-if="
                              editedItem.hasChildren ||
                              editedItem.idMaritalStatus === 3 ||
                              editedItem.idMaritalStatus === 2 ||
                              editedItem.idMaritalStatus === 4
                            "
                          >
                            <v-form>
                              <v-simple-table dense class="no-padding">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-center">Nature du lien</th>
                                      <th class="text-center">Nom de famille</th>
                                      <th class="text-center">Prénom</th>
                                      <th class="text-center">Contact d'urgence</th>
                                      <th class="text-center">Téléphone</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(familyMember, index) in editedItem.familyMembers" v-bind:key="index">
                                      <td class="text-left">
                                        <v-select
                                          class="mt-6"
                                          dense
                                          :id="`input-family-${index}`"
                                          outlined
                                          v-model="familyMember.id_account_family_link"
                                          :items="familyOptions"
                                          item-text="designation"
                                          item-value="id"
                                        ></v-select>
                                      </td>
                                      <td class="text-left">
                                        <v-text-field
                                          ref="newTextField"
                                          outlined
                                          class="mt-6"
                                          dense
                                          v-model="familyMember.lastname"
                                          single-line
                                        ></v-text-field>
                                      </td>

                                      <td class="text-left">
                                        <v-text-field
                                          ref="newTextField"
                                          outlined
                                          class="mt-6"
                                          dense
                                          v-model="familyMember.firstname"
                                          single-line
                                        ></v-text-field>
                                      </td>

                                      <td class="text-center justify-center">
                                        <v-switch class="ml-7" inset v-model="familyMember.isEmergencyContact">
                                        </v-switch>
                                      </td>
                                      <td class="text-left">
                                        <v-text-field
                                          v-if="familyMember.isEmergencyContact"
                                          ref="newTextField"
                                          outlined
                                          class="mt-6"
                                          dense
                                          v-model="familyMember.phoneNumber"
                                          single-line
                                        ></v-text-field>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                              <v-row>
                                <v-col cols="12" md="4">
                                  <v-btn
                                    style="text-transform: none"
                                    elevation="0"
                                    @click="addLine(editedItem.familyMembers)"
                                  >
                                    <v-icon left>mdi-plus-circle</v-icon> Ajouter un membre à la famille
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-content step="2" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Relation contractuelle avec l'entreprise</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      id="input-date-entry"
                                      dense
                                      prepend-inner-icon="mdi-phone"
                                      outlined
                                      type="date"
                                      v-model="editedItem.entryDate"
                                      label="Date d'entrée dans l'entreprise"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6"> </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-contract"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      v-model="editedItem.idTypeContract"
                                      :items="typeContracts"
                                      label="Type de relation"
                                      item-text="designation"
                                      item-value="id"
                                    >
                                      <template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-contract-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-employment"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      :items="typeEmployments"
                                      item-text="designation"
                                      item-value="id"
                                      label="Type d'emploi"
                                      v-model="editedItem.idTypeEmployment"
                                      ><template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-employment-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-job"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      v-model="editedItem.idTypeJob"
                                      :items="typeJobs"
                                      item-text="designation"
                                      item-value="id"
                                      label="Poste"
                                      ><template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-job-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-convention"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      :items="[
                                        {
                                          id: 1,
                                          designation: `Convention collective des agents généraux d'assurance`,
                                        },
                                        {
                                          id: 2,
                                          designation: `Convention collective du courtage d'assurance`,
                                        },
                                      ]"
                                      item-text="designation"
                                      item-value="id"
                                      label="Convention"
                                      v-model="editedItem.idCollectiveAgreement"
                                    >
                                      <template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-qualification-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template></v-select
                                    >
                                  </v-col>
                                </v-row>
                                <v-row v-if="editedItem.idConvention === 1">
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      id="select-type-qualification"
                                      dense
                                      prepend-inner-icon="mdi-cogs"
                                      outlined
                                      :items="typeQualifications"
                                      item-text="designation"
                                      item-value="id"
                                      label="Classification du poste"
                                      v-model="editedItem.idTypeQualification"
                                    >
                                      <template v-slot:item="{ item }">
                                        <v-list-item-content :id="`item-type-qualification-${item.id}`">
                                          <v-list-item-title>{{ item.designation }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template></v-select
                                    >
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>
                        </v-stepper-content>

                        <v-stepper-content step="3" class="no-padding">
                          <v-card-title>
                            <span class="text-h5">Inviter vos collaborateurs</span>
                          </v-card-title>
                          <v-card-text>
                            <v-combobox
                              :disabled="emailLoading"
                              label="Entrez vos adresses email"
                              v-model="emailInput"
                              placeholder="Email1;Email2;Email3;..."
                              chips
                              multiple
                              @keyup.enter="testEmail"
                              color="EoleBlue"
                            >
                            </v-combobox>
                          </v-card-text>
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                    <v-card-actions>
                      <v-btn id="btn-last" v-if="stepperAccountTutorial > 0" text @click="stepperAccountTutorial--"
                        >retour</v-btn
                      >
                      <v-spacer></v-spacer>

                      <v-btn id="btn-next" v-if="stepperAccountTutorial < 2" text @click="stepperAccountTutorial++"
                        >suivant</v-btn
                      >
                
                      <v-btn
                        id="btn-validate"
                        v-if="stepperAccountTutorial === 2"
                        class="white--text"
                        color="EoleGreen"
                        @click="stepperAccountTutorial++"
                      >
                        Valider
                      </v-btn>
                      <v-btn
                        :disabled="emailLoading"
                        :loading="emailLoading"
                        id="btn-invitation"
                        v-if="stepperAccountTutorial === 3"
                        class="white--text"
                        color="EoleGreen"
                        @click="sendInvitationMail"
                      >
                        Inviter mes collaborateurs
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog> -->

      <v-carousel v-if="isGuest" class="carousel rounded-xl" cycle interval="10000" height="460" show-arrows-on-hover>
        <v-carousel-item v-for="(slide, i) in slides" :key="i" :src="slide.url">
          <v-row class="carousel pa-15" justify="center">
            <v-col cols="12" md="12">
              <v-img max-width="250px" :src="require(`../../../../src/assets/images/logos/anavel_logo.png`)"></v-img>
            </v-col>

            <v-col v-if="!slide.videoAvailable">
              <v-card-title class="text-h4">{{ slide.title }}</v-card-title>
              <v-card-subtitle v-if="user.grade === 'franchiseur'"
                >Bonjour <strong>{{ user.name }},</strong> <br />
                Bienvenue sur votre espace courtage « Mes Services Assurance » <br />
                Récupérer tous vos contrats ou attestations en un clin d'oeil grâce aux outils proposés
                !</v-card-subtitle
              >
              <v-card-subtitle v-else-if="user.grade === 'client'"
                >Bonjour <strong>{{ user.name }},</strong> <br />
                Bienvenue sur votre espace client « Mes Services Assurance » <br />
                Récupérer tous vos contrats ou attestations en un clin d'oeil grâce aux outils proposés
                !</v-card-subtitle
              >
              <v-card-subtitle v-else
                >Bonjour <strong>{{ user.name }},</strong> <br />
                Bienvenue sur votre espace collaborateur « Mes Services Assurance » <br />
                Générer vos documents papiers, gérer vos clients depuis votre espace grâce aux outils proposés
                !</v-card-subtitle
              >
            </v-col>
            <v-col v-else>
              <v-card-title class="text-h4 mb-5">{{ slide.title }}</v-card-title>
              <v-card-subtitle>
                <iframe
                  width="560"
                  height="300"
                  :src="`${slide.url}?controls=0`"
                  title="Anavel Assurance"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe
              ></v-card-subtitle>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
      <v-row v-else class="mt-5">
        <v-col v-if="checkRouteIDExist(40)" class="d-flex" cols="12" md="3" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
                  >Mes rendez-vous programmés</v-card-subtitle
                >
                <v-card-text v-if="appointmentsAttributed.length > 0" class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-briefcase fa-4x EoleBlue--text"></i>
                  <p class="mt-5">{{ appointmentsAttributed.length }}</p>
                </v-card-text>
                <v-card-text v-else class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-briefcase fa-4x EoleBlue--text"></i>
                  <p class="mt-5">Aucun rendez-vous programmés</p>
                </v-card-text>

                <v-row class="mt-n13 mb-n8">
                  <v-col cols="12" md="12">
                    <v-card-subtitle
                      class="text-center text-h5 EoleBlue--text font-weight-bold"
                      style="font-size: 0.9rem !important"
                      >Dernière actualisation : {{ currentDatetime }}
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card>
              <!-- <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
                <v-card-subtitle>
                  <v-avatar color="EoleBlueLighten" class="mr-2">
                    <i
                      class="fa-regular fa-calendar-check fa-1x EoleBlue--text"
                      style="font-size: 1.5rem"
                    ></i> </v-avatar
                  >Mes rendez-vous programmés</v-card-subtitle
                >
                <div v-if="appointmentsAttributed.length > 0" class="no-padding">
                  <v-card-text class="text-center">
                    <h2 class="font-weight-bold" style="font-size: 2rem">{{ appointmentsAttributed.length }}</h2>
                  </v-card-text>
                </div>
                <v-card-text v-else class="text-center">
                  <p>Vous n'avez aucun rendez-vous pour le moment.</p>
                </v-card-text>
              </v-card> -->
            </template>
            <span>Les rendez-vous en attente sont affichés dans la liste ci-dessous. </span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="12" md="3" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
                  >Mes demandes de rappel</v-card-subtitle
                >
                <v-card-text v-if="contactNumber > 0" class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-phone-volume fa-4x EoleBlue--text"></i>
                  <p class="mt-5">Vous avez {{ contactNumber }} demandes de rappel en cours.</p>
                </v-card-text>
                <v-card-text v-else class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-phone-volume fa-4x EoleBlue--text"></i>
                  <p class="mt-5">Aucune demande de rappels.</p>
                </v-card-text>

                <v-row class="mt-n13 mb-n8">
                  <v-col cols="12" md="12">
                    <v-card-subtitle
                      class="text-center text-h5 EoleBlue--text font-weight-bold"
                      style="font-size: 0.9rem !important"
                      >Dernière actualisation : {{ currentDatetime }}
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card>
              <!-- <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
                <v-card-subtitle>
                  <v-avatar color="EoleBlueLighten" class="mr-2">
                    <i
                      class="fa-regular fa-calendar-check fa-1x EoleBlue--text"
                      style="font-size: 1.5rem"
                    ></i> </v-avatar
                  >Mes rendez-vous programmés</v-card-subtitle
                >
                <div v-if="appointmentsAttributed.length > 0" class="no-padding">
                  <v-card-text class="text-center">
                    <h2 class="font-weight-bold" style="font-size: 2rem">{{ appointmentsAttributed.length }}</h2>
                  </v-card-text>
                </div>
                <v-card-text v-else class="text-center">
                  <p>Vous n'avez aucun rendez-vous pour le moment.</p>
                </v-card-text>
              </v-card> -->
            </template>
            <span>Vos demandes de rappel non traités. </span>
          </v-tooltip>
        </v-col>

        <v-col class="d-flex" cols="12" md="3" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
                  >Mes prochaines vacances</v-card-subtitle
                >
                <v-card-text v-if="account.next_holiday != null" class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-umbrella-beach fa-4x EoleBlue--text"></i>
                  <p class="mt-5">Vos prochaines vacances</p>

                  <v-row class="mt-n8 mb-n8">
                    <v-col cols="12" md="12">
                      <v-card-subtitle
                        class="text-center text-h5 EoleBlue--text font-weight-bold"
                        style="font-size: 0.9rem !important"
                        >du {{ account.next_holiday.start }} au {{ account.next_holiday.end }}
                      </v-card-subtitle>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-umbrella-beach fa-4x EoleBlue--text"></i>
                  <p class="mt-5">Vous n'avez pas posé de prochaines vacances.</p>

                  <!-- <v-row class="mt-n8 mb-n8">
                    <v-col cols="12" md="12">
                      <v-card-subtitle
                        class="text-center text-h5 EoleBlue--text font-weight-bold"
                        style="font-size: 0.9rem !important"
                        >Dernière actualisation : {{ currentDatetime }}
                      </v-card-subtitle>
                    </v-col>
                  </v-row> -->
                </v-card-text>
              </v-card>
              <!-- <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
                <v-card-subtitle>
                  <v-avatar color="EoleBlueLighten" class="mr-2">
                    <i
                      class="fa-regular fa-calendar-check fa-1x EoleBlue--text"
                      style="font-size: 1.5rem"
                    ></i> </v-avatar
                  >Mes rendez-vous programmés</v-card-subtitle
                >
                <div v-if="appointmentsAttributed.length > 0" class="no-padding">
                  <v-card-text class="text-center">
                    <h2 class="font-weight-bold" style="font-size: 2rem">{{ appointmentsAttributed.length }}</h2>
                  </v-card-text>
                </div>
                <v-card-text v-else class="text-center">
                  <p>Vous n'avez aucun rendez-vous pour le moment.</p>
                </v-card-text>
              </v-card> -->
            </template>
            <span>Vos congés posés. </span>
          </v-tooltip>
        </v-col>

        <v-col class="d-flex" cols="12" md="3" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">Statistiques</v-card-subtitle>
                <v-card-text v-if="!!account.contact_last_week" class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-chart-pie fa-4x EoleBlue--text"></i>
                  <p class="mt-5">
                    {{ agency[0].name }} à reçus {{ account.contact_last_week.contact_count }} demandes de rappel
                  </p>
                  <v-row class="mt-n8 mb-n8">
                    <v-col cols="12" md="12">
                      <v-card-subtitle
                        class="text-center text-h5 EoleBlue--text font-weight-bold"
                        style="font-size: 0.9rem !important"
                        >Du {{ account.contact_last_week.start_date }} au {{ account.contact_last_week.end_date }}
                      </v-card-subtitle>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-chart-pie fa-4x EoleBlue--text"></i>
                  <p class="mt-5">Aucune données disponibles.</p>
                </v-card-text>
              </v-card>
              <!-- <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
                <v-card-subtitle>
                  <v-avatar color="EoleBlueLighten" class="mr-2">
                    <i
                      class="fa-regular fa-calendar-check fa-1x EoleBlue--text"
                      style="font-size: 1.5rem"
                    ></i> </v-avatar
                  >Mes rendez-vous programmés</v-card-subtitle
                >
                <div v-if="appointmentsAttributed.length > 0" class="no-padding">
                  <v-card-text class="text-center">
                    <h2 class="font-weight-bold" style="font-size: 2rem">{{ appointmentsAttributed.length }}</h2>
                  </v-card-text>
                </div>
                <v-card-text v-else class="text-center">
                  <p>Vous n'avez aucun rendez-vous pour le moment.</p>
                </v-card-text>
              </v-card> -->
            </template>
            <span>Demandes de rappels la semaine dernière pour votre cabinet. </span>
          </v-tooltip>
        </v-col>

        <!-- <v-col class="d-flex" cols="12" md="3" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
                  >Ma prochaine formation</v-card-subtitle
                >
                <v-card-text v-if="!!account.contact_last_week" class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-chalkboard-user fa-4x EoleBlue--text"></i>
                  <p class="mt-5">
                    Vous avez une formation prévue le {{ account.next_training.date }} sur le thème
                    {{ account.next_training.designation }} dispensé par {{ account.next_training.organism }}
                  </p>
                </v-card-text>
                <v-card-text v-else class="text-center pa-5 mt-n5">
                  <i class="fa-solid fa-chart-pie fa-4x EoleBlue--text"></i>
                  <p class="mt-5">Aucune données disponibles.</p>
                </v-card-text>
              </v-card>
     
            </template>
            <span>Demandes de rappels la semaine dernière pour votre cabinet. </span>
          </v-tooltip>
        </v-col> -->
        <!-- <v-col class="d-flex" cols="12" md="3">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card class="flex d-flex flex-column" v-bind="attrs" v-on="on" outlined>
                <v-card-subtitle
                  ><v-avatar color="EoleBlueLighten" class="mr-2">
                    <i class="fa-solid fa-star fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                  </v-avatar>
                  Mes clients VIP</v-card-subtitle
                >

                <v-row align="center">
                  <v-card-text v-if="customersCountVIP > 0" class="text-center align-center">
                    <h2 class="font-weight-bold" style="font-size: 2rem">{{ customersCountVIP }}</h2>
                  </v-card-text>
                  <v-card-text v-else class="text-center">
                    <p>Vous n'avez aucun client VIP pour le moment.</p>
                  </v-card-text>
                </v-row>
              </v-card>
            </template>
            <span>Affiche le nombre de client(s) considéré comme VIP. </span>
          </v-tooltip>
        </v-col> -->
      </v-row>

      <custome-loader v-if="waitTimeoutContact"></custome-loader>
      <div>
        <v-row no-gutters class="mt-5">
          <v-col md="5">
            <h2 v-if="servicesItems.length" class="my-4 EoleYellow--text title-text">
              MES SERVICES ASSURANCES
              <v-menu v-if="isAdmin && servicesBuyable.length > 0" right transition="slide-x-transition" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="servicesBuyable.length === 0"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    style=""
                    class="justify-center text-center EoleBlue white--text ml-3 align-center btn-add-module"
                    x-small
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-subheader class="ml-2">Intégrer un nouveau module</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    link
                    color="EoleBlue"
                    dense
                    v-for="module in servicesBuyable"
                    v-bind:key="module.id"
                    @click="addAdditionnalRoute(module.id)"
                  >
                    <v-list-item-title> Intégrer le module {{ module.content }} </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </h2>
            <v-row :class="$vuetify.breakpoint.xs ? 'justify-center' : ''">
              <v-col
                v-if="
                  (service.superadmin && isSuperAdmin) ||
                  (service.admin && isAdmin) ||
                  (service.collaborator && isCollaborator) ||
                  (service.guest && isGuest) ||
                  (service.manager && isManager)
                "
                v-for="service in servicesItems"
                :key="service.id"
                class="text-center d-flex"
                cols="12"
                md="3"
                sm="6"
              >
                <v-tooltip v-model="showDescriptionService[service.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="service.disable"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="service.disable ? '#' : service.path"
                        :target="service.otherSite ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          service.disable
                            ? 'disableCursor rounded-xl flex d-flex flex-column justify-center'
                            : 'rounded-xl flex d-flex flex-column justify-center ',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[
                              service.iconClass,
                              'EoleBlue--text',
                              hover ? [service.animationClass, 'EoleYellow--text'] : '',
                            ]"
                          ></i>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text card-title">{{ service.content }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>
                  <span>{{ service.content }}</span>
                </v-tooltip>
                <v-btn
                  v-if="service.is_additional"
                  bordered
                  fab
                  x-small
                  style="top: -15px; left: -25px"
                  @click="deleteAdditionnalRoute(service.id_route)"
                  elevation="10"
                >
                  <v-icon color="error"> mdi-minus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="1"></v-col>
          <v-col md="5">
            <h2 v-if="toolsItems.length" class="my-4 EoleYellow--text title-text">
              MES OUTILS CABINET

              <v-menu v-if="isAdmin && toolsBuyable.length > 0" right transition="slide-x-transition" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="toolsBuyable.length === 0"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    style=""
                    class="justify-center text-center EoleBlue white--text ml-3 align-center btn-add-module"
                    x-small
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-subheader class="ml-2">Intégrer un nouveau module</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    link
                    color="EoleBlue"
                    dense
                    v-for="module in toolsBuyable"
                    v-bind:key="module.id"
                    @click="addAdditionnalRoute(module.id)"
                  >
                    <v-list-item-title> Intégrer le module {{ module.content }} </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </h2>
            <v-row :class="$vuetify.breakpoint.xs ? 'justify-center' : 'mt-1'">
              <v-col
                v-if="
                  (tool.superadmin && isSuperAdmin) ||
                  (tool.admin && isAdmin) ||
                  (tool.collaborator && isCollaborator) ||
                  (tool.guest && isGuest) ||
                  (tool.manager && isManager)
                "
                v-for="tool in toolsItems"
                :key="tool.id"
                class="text-center d-flex"
                cols="12"
                md="3"
                sm="6"
              >
                <v-tooltip v-model="showDescriptionTool[tool.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="tool.disable"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="tool.disable ? '#' : tool.path"
                        :target="tool.otherSite ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          tool.disable
                            ? 'disableCursor rounded-xl flex d-flex flex-column justify-center'
                            : 'rounded-xl flex d-flex flex-column justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <v-badge
                            v-if="checkRouteIDExist(36) && tool.id_route === 36 && contactNumber > 0"
                            :content="contactNumber"
                            color="EoleYellow"
                            overlap
                            right
                            bottom
                            class="mr-2"
                            bordered
                          >
                            <i
                              :class="[
                                tool.iconClass,
                                'EoleBlue--text',
                                hover ? [tool.animationClass, 'EoleYellow--text'] : '',
                              ]"
                            ></i>
                          </v-badge>
                          <i
                            v-else
                            :class="[
                              tool.iconClass,
                              'EoleBlue--text',
                              hover ? [tool.animationClass, 'EoleYellow--text'] : '',
                            ]"
                          ></i>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text card-title">{{ tool.content }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>
                  <span>{{ tool.content }}</span>
                </v-tooltip>
                <v-btn
                  v-if="tool.is_additional"
                  bordered
                  fab
                  x-small
                  style="top: -15px; left: -25px"
                  @click="deleteAdditionnalRoute(tool.id_route)"
                  elevation="10"
                >
                  <v-icon color="error"> mdi-minus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- GESTION DE MES CLIENTS -->
        <v-row no-gutters class="mt-5">
          <v-col md="5">
            <h2 class="my-4 EoleYellow--text title-text">
              GESTION DE MES CLIENTS
              <v-menu v-if="isAdmin && clientsBuyable.length > 0" right transition="slide-x-transition" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="isAdmin"
                    :disabled="clientsBuyable.length === 0"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    style=""
                    class="justify-center text-center EoleBlue white--text ml-3 align-center btn-add-module"
                    x-small
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-subheader class="ml-2">Intégrer un nouveau module</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    link
                    color="EoleBlue"
                    dense
                    v-for="module in clientsBuyable"
                    v-bind:key="module.id"
                    @click="addAdditionnalRoute(module.id)"
                  >
                    <v-list-item-title> Intégrer le module {{ module.content }} </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </h2>
            <v-row v-if="clientItems.length > 0" :class="$vuetify.breakpoint.xs ? 'justify-center' : ''">
              <v-col
                v-if="
                  (client.superadmin && isSuperAdmin) ||
                  (client.admin && isAdmin) ||
                  (client.collaborator && isCollaborator) ||
                  (client.guest && isGuest) ||
                  (client.manager && isManager)
                "
                v-for="client in clientItems"
                :key="client.id"
                class="text-center d-flex"
                cols="12"
                md="3"
                sm="6"
              >
                <v-tooltip v-model="showDescriptionClient[client.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="client.disable"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="client.disable ? '#' : client.path"
                        :target="client.otherSite ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          client.disable
                            ? 'disableCursor rounded-xl flex d-flex flex-column justify-center'
                            : 'rounded-xl flex d-flex flex-column justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[
                              client.iconClass,
                              'EoleBlue--text',
                              hover ? [client.animationClass, 'EoleYellow--text'] : '',
                            ]"
                          ></i>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text card-title">{{ client.content }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>
                  <span>{{ client.content }}</span>
                </v-tooltip>
                <v-btn
                  v-if="client.is_additional"
                  bordered
                  fab
                  x-small
                  style="top: -15px; left: -25px"
                  @click="deleteAdditionnalRoute(client.id_route)"
                  elevation="10"
                >
                  <v-icon color="error"> mdi-minus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" md="3" v-for="i in 3" v-bind:key="i">
                <v-skeleton-loader
                  type="image"
                  class="rounded-xl"
                  max-width="125px"
                  max-height="125px"
                  elevation="3"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="1"></v-col>
          <v-col md="5">
            <h2 class="my-4 EoleYellow--text title-text">
              MES EXTRANETS CLIENTS
              <v-menu v-if="isAdmin && extranetsBuyable.length > 0" right transition="slide-x-transition" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="isAdmin"
                    :disabled="extranetsBuyable.length === 0"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    style=""
                    class="justify-center text-center EoleBlue white--text ml-3 align-center btn-add-module"
                    x-small
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-subheader class="ml-2">Intégrer un nouveau module</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    link
                    color="EoleBlue"
                    dense
                    v-for="module in extranetsBuyable"
                    v-bind:key="module.id"
                    @click="addAdditionnalRoute(module.id)"
                  >
                    <v-list-item-title> Intégrer le module {{ module.content }} </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </h2>
            <v-row v-if="extranetsItems.length" :class="$vuetify.breakpoint.xs ? 'justify-center' : ''">
              <v-col
                v-if="
                  (extranet.superadmin && isSuperAdmin) ||
                  (extranet.admin && isAdmin) ||
                  (extranet.collaborator && isCollaborator) ||
                  (extranet.guest && isGuest) ||
                  (extranet.manager && isManager)
                "
                v-for="extranet in extranetsItems"
                :key="extranet.id"
                class="text-center d-flex"
                cols="12"
                md="3"
                sm="6"
              >
                <v-tooltip v-model="showDescriptionExtranet[extranet.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="extranet.disable"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="extranet.disable ? '#' : extranet.path"
                        :target="extranet.otherSite ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          extranet.disable
                            ? 'disableCursor rounded-xl flex d-flex flex-column justify-center'
                            : 'rounded-xl flex d-flex flex-column justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[
                              extranet.iconClass,
                              'EoleBlue--text',
                              hover ? [extranet.animationClass, 'EoleYellow--text'] : '',
                            ]"
                          ></i>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text card-title">{{ extranet.content }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>
                  <span>{{ extranet.content }}</span>
                </v-tooltip>
                <v-btn
                  v-if="extranet.is_additional"
                  bordered
                  fab
                  x-small
                  style="top: -15px; left: -25px"
                  @click="deleteAdditionnalRoute(extranet.id_route)"
                  elevation="10"
                >
                  <v-icon color="error"> mdi-minus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" md="3" v-for="i in 3" v-bind:key="i">
                <v-skeleton-loader
                  type="image"
                  class="rounded-xl"
                  max-width="125px"
                  max-height="125px"
                  elevation="3"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- EN DÉVELOPPEMENT -->
        <v-row v-if="isAdmin || isSuperAdmin" no-gutters class="mt-5">
          <v-col md="5">
            <h2 v-if="wipItems.length" class="my-4 EoleYellfow--text title-text">
              EN DÉVELOPPEMENT
              <v-menu v-if="isAdmin && wipBuyable.length > 0" right transition="slide-x-transition" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="isAdmin"
                    :disabled="wipBuyable.length === 0"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    style=""
                    class="justify-center text-center EoleBlue white--text ml-3 align-center btn-add-module"
                    x-small
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-subheader class="ml-2">Intégrer un nouveau module</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    link
                    color="EoleBlue"
                    dense
                    v-for="module in wipBuyable"
                    v-bind:key="module.id"
                    @click="addAdditionnalRoute(module.id)"
                  >
                    <v-list-item-title> Intégrer le module {{ module.content }} </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </h2>
            <v-row :class="$vuetify.breakpoint.xs ? 'justify-center' : ''">
              <v-col
                v-if="(wip.admin && isAdmin) || (wip.superadmin && isSuperAdmin)"
                v-for="wip in wipItems"
                :key="wip.id"
                class="text-center d-flex"
                cols="12"
                md="3"
                sm="6"
              >
                <v-tooltip v-model="showDescriptionWip[wip.id]" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :disabled="wip.disable"
                        @mouseover="hover = true"
                        @mouseout="hover = false"
                        outlined
                        elevation="3"
                        :href="wip.disable ? '#' : wip.path"
                        :target="wip.otherSite ? '_blank' : ''"
                        max-width="125px"
                        max-height="125px"
                        :class="[
                          wip.disable
                            ? 'disableCursor rounded-xl flex d-flex flex-column justify-center'
                            : 'rounded-xl flex d-flex flex-column justify-center',
                          hover ? 'hover-card-dashboard' : '',
                        ]"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-card-text class="text-h7">
                          <i
                            :class="[
                              wip.iconClass,
                              'EoleBlue--text',
                              hover ? [wip.animationClass, 'EoleYellow--text'] : '',
                            ]"
                          ></i>
                        </v-card-text>
                        <v-card-text class="text-caption EoleBlue--text card-title">{{ wip.content }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </template>
                  <span>{{ wip.content }}</span>
                </v-tooltip>
                <v-btn
                  v-if="wip.is_additional"
                  bordered
                  fab
                  x-small
                  style="top: -15px; left: -25px"
                  @click="deleteAdditionnalRoute(wip.id_route)"
                  elevation="10"
                >
                  <v-icon color="error"> mdi-minus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>
<script>
import config from "@/views/config/config"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"
import moment from "moment"

import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")

import VueApexCharts from "vue-apexcharts"

import dialogControl from "./components/Control.vue"

import { getCookie } from "@/utils/cookies"
import { checkRouteIDExist } from "@/utils/routes"

export default {
  data: () => ({
    step: 1, // Suivre la progression du stepper
    adminName: "", // Exemple de champs de formulaire
    adminEmail: "", // Exemple de champs de formulaire
    routesBuyable: [],
    currentDatetime: moment().calendar(),
    controlled: true,
    jwt: getCookie("jwt"),
    series: [],
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        // categories: [
        //   "01/01/2011 GMT",
        //   "01/02/2011 GMT",
        //   "01/03/2011 GMT",
        //   "01/04/2011 GMT",
        //   "01/05/2011 GMT",
        //   "01/06/2011 GMT",
        // ],
      },
      // legend: {
      //   position: "bottom",
      //   offsetY: 40,
      // },
      fill: {
        opacity: 1,
      },
    },

    statistics: [],
    account: {
      next_holiday: [],
    },
    emailLoading: false,
    emailInput: [],
    emailList: [], // Tableau pour stocker les adresses email
    currentInput: "",
    emails: [],
    stepperTutorial: 0,
    states: ["administrateur"],
    cityItems: [],
    cityItemsProfessionnal: [],
    searchCity: [],
    searchCityProfessionnal: [],

    agency: [],
    /* end test purpose */
    passwordValidation: null,
    lastnameErrorMessage: "",
    firstnameErrorMessage: "",
    emailErrorMessage: "",
    phoneNumberErrorMessage: "",
    validityFormPassword: true,
    password: "",
    showPassword: false,
    passwordValidationMessage: null,
    passwordErrorMessage: "",
    basicRules: [v => !!v || "Ce champ est requis"],
    phoneNumberRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "Un numéro de téléphone Français est composé de 10 chiffres",
    ],
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 12 || "Minimum 12 caractères",
    },
    emailRules: [
      v => !!v || "Une adresse électronique est requise",
      v => /.+@.+\..+/.test(v) || "L'adresse électronique doit être valide : example@gmail.com",
    ],
    stepperAccountTutorial: 0,
    editedItem: {
      id: null,
      firstname: "",
      lastname: "",
      lastConnection: "",
      lastIPConnected: "",
      statut: "utilisateur",
      email: "",
      phoneNumber: "",
      serviceChoose: "",
      idTypeContract: null,
      idTypeEmployment: null,
      idTypeJob: null,
      idTypeQualification: null,
      familyMembers: [],
      idMaritalStatus: 1,
      street: "",
      city: null,
      zip: "",
    },
    editedIndex: 0,
    complaintsCount: 0,
    customersCountVIP: 0,
    customersCount: 0,
    appointmentsAttributed: [],
    appointmentNumber: 0,
    isSuperAdmin: false,
    isAdmin: false,
    isGuest: false,
    isCollaborator: false,
    isManager: false,
    grade: null,
    showDescriptionTool: [null],
    showDescriptionService: [null],
    showDescriptionClient: [null],
    showDescriptionWip: [null],
    showDescriptionExtranet: [null],

    waitTimeoutContact: false,
    contactNumber: 0,
    test: false,
    colors: ["indigo", "warning", "pink darken-2", "red lighten-1", "deep-purple accent-4"],
    slides: [],
    rate: false,
    rateValue: 5,

    interlocutors: [],
    isSearch: false,
    loading: false,
    search: null,
    select: null,
    services: [],
    panel: [0],
    apiResponse: null,

    items: [],
    drawer: true,
    breadcrumbsItems: [
      {
        text: "Accueil",
        disabled: false,
        href: "/",
      },
    ],
    searchEtablishment: null,
    typeEmployments: [],
    typeContracts: [],
    typeJobs: [],
    typeQualifications: [],
  }),
  computed: {
    servicesBuyable() {
      return this.routesBuyable
        .filter(route => route.id_routes_category === 1)
        .map(route => {
          return {
            id: route.id,
            content: route.description,
          }
        })
    },
    toolsBuyable() {
      return this.routesBuyable
        .filter(route => route.id_routes_category === 2)
        .map(route => {
          return {
            id: route.id,
            content: route.description,
          }
        })
    },
    clientsBuyable() {
      return this.routesBuyable
        .filter(route => route.id_routes_category === 3)
        .map(route => {
          return {
            id: route.id,
            content: route.description,
          }
        })
    },
    extranetsBuyable() {
      return this.routesBuyable
        .filter(route => route.id_routes_category === 4)
        .map(route => {
          return {
            id: route.id,
            content: route.description,
          }
        })
    },
    wipBuyable() {
      return this.routesBuyable
        .filter(route => route.id_routes_category === 5)
        .map(route => {
          return {
            id: route.id,
            content: route.description,
          }
        })
    },
    servicesItems() {
      return this.$store.state.authorizedRoutes
        .filter(route => route.id_category === 1 && route.hidden_dashboard === 0)
        .map(route => {
          this.$set(this.showDescriptionService, route.id, false)

          // Vérifiez si l'objet route a la clé 'is_additionnal'
          if ("is_additional" in route) {
            return {
              id: route.id,
              id_route: route.id_route,
              content: route.description,
              path: route.path,
              iconClass: route.icon_class,
              animationClass: route.animation_class,
              disable: route.disabled,
              otherSite: false,
              superadmin: route.RIGHT_SUPERADMIN,

              admin: route.RIGHT_ADMIN,
              guest: route.RIGHT_GUEST,
              collaborator: route.RIGHT_COLLABORATOR,
              manager: route.RIGHT_MANAGER,
              is_additional: route.is_additional,
            }
          }

          return {
            id: route.id,
            id_route: route.id_route,
            content: route.description,
            path: route.path,
            iconClass: route.icon_class,
            animationClass: route.animation_class,
            disable: route.disabled,
            otherSite: false,
            superadmin: route.RIGHT_SUPERADMIN,

            admin: route.RIGHT_ADMIN,
            guest: route.RIGHT_GUEST,
            collaborator: route.RIGHT_COLLABORATOR,
            manager: route.RIGHT_MANAGER,
          }
        })
    },
    toolsItems() {
      return this.$store.state.authorizedRoutes
        .filter(route => route.id_category === 2 && route.hidden_dashboard === 0)
        .map(route => {
          this.$set(this.showDescriptionTool, route.id, false)

          // Vérifiez si l'objet route a la clé 'is_additionnal'
          if ("is_additional" in route) {
            return {
              id: route.id,
              id_route: route.id_route,
              content: route.description,
              path: route.path,
              iconClass: route.icon_class,
              animationClass: route.animation_class,
              disable: route.disabled,
              otherSite: false,
              superadmin: route.RIGHT_SUPERADMIN,

              admin: route.RIGHT_ADMIN,
              guest: route.RIGHT_GUEST,
              collaborator: route.RIGHT_COLLABORATOR,
              manager: route.RIGHT_MANAGER,
              is_additional: route.is_additional,
            }
          }
          return {
            id: route.id,
            id_route: route.id_route,

            content: route.description,
            path: route.path,
            iconClass: route.icon_class,
            animationClass: route.animation_class,
            disable: route.disabled,
            otherSite: false,
            superadmin: route.RIGHT_SUPERADMIN,

            admin: route.RIGHT_ADMIN,
            guest: route.RIGHT_GUEST,
            collaborator: route.RIGHT_COLLABORATOR,
            manager: route.RIGHT_MANAGER,
          }
        })
    },
    clientItems() {
      return this.$store.state.authorizedRoutes
        .filter(route => route.id_category === 3 && route.hidden_dashboard === 0)
        .map(route => {
          this.$set(this.showDescriptionClient, route.id, false)

          // Vérifiez si l'objet route a la clé 'is_additionnal'
          if ("is_additional" in route) {
            return {
              id: route.id,
              id_route: route.id_route,
              content: route.description,
              path: route.path,
              iconClass: route.icon_class,
              animationClass: route.animation_class,
              disable: route.disabled,
              otherSite: false,
              superadmin: route.RIGHT_SUPERADMIN,

              admin: route.RIGHT_ADMIN,
              guest: route.RIGHT_GUEST,
              collaborator: route.RIGHT_COLLABORATOR,
              manager: route.RIGHT_MANAGER,
              is_additional: route.is_additional,
            }
          }
          return {
            id: route.id,
            id_route: route.id_route,

            content: route.description,
            path: route.path,
            iconClass: route.icon_class,
            animationClass: route.animation_class,
            disable: route.disabled,
            otherSite: false,
            superadmin: route.RIGHT_SUPERADMIN,

            admin: route.RIGHT_ADMIN,
            guest: route.RIGHT_GUEST,
            collaborator: route.RIGHT_COLLABORATOR,
            manager: route.RIGHT_MANAGER,
          }
        })
    },
    extranetsItems() {
      return this.$store.state.authorizedRoutes
        .filter(route => route.id_category === 4 && route.hidden_dashboard === 0)
        .map(route => {
          this.$set(this.showDescriptionExtranet, route.id, false)

          // Vérifiez si l'objet route a la clé 'is_additionnal'
          if ("is_additional" in route) {
            return {
              id: route.id,
              id_route: route.id_route,
              content: route.description,
              path: route.path,
              iconClass: route.icon_class,
              animationClass: route.animation_class,
              disable: route.disabled,
              otherSite: false,
              superadmin: route.RIGHT_SUPERADMIN,
              admin: route.RIGHT_ADMIN,
              guest: route.RIGHT_GUEST,
              collaborator: route.RIGHT_COLLABORATOR,
              manager: route.RIGHT_MANAGER,
              is_additional: route.is_additional,
            }
          }
          return {
            id: route.id,
            id_route: route.id_route,

            content: route.description,
            path: route.path,
            iconClass: route.icon_class,
            animationClass: route.animation_class,
            disable: route.disabled,
            otherSite: false,
            superadmin: route.RIGHT_SUPERADMIN,

            admin: route.RIGHT_ADMIN,
            guest: route.RIGHT_GUEST,
            collaborator: route.RIGHT_COLLABORATOR,
            manager: route.RIGHT_MANAGER,
          }
        })
    },
    wipItems() {
      return this.$store.state.authorizedRoutes
        .filter(route => route.id_category === 5 && route.hidden_dashboard === 0)
        .map(route => {
          this.$set(this.showDescriptionWip, route.id, false)

          // Vérifiez si l'objet route a la clé 'is_additionnal'
          if ("is_additional" in route) {
            return {
              id: route.id,
              id_route: route.id_route,
              content: route.description,
              path: route.path,
              iconClass: route.icon_class,
              animationClass: route.animation_class,
              disable: route.disabled,
              otherSite: false,
              superadmin: route.RIGHT_SUPERADMIN,

              admin: route.RIGHT_ADMIN,
              guest: route.RIGHT_GUEST,
              collaborator: route.RIGHT_COLLABORATOR,
              manager: route.RIGHT_MANAGER,
              is_additional: route.is_additional,
            }
          }
          return {
            id: route.id,
            id_route: route.id_route,

            content: route.description,
            path: route.path,
            iconClass: route.icon_class,
            animationClass: route.animation_class,
            disable: route.disabled,
            otherSite: false,
            superadmin: route.RIGHT_SUPERADMIN,

            admin: route.RIGHT_ADMIN,
            guest: route.RIGHT_GUEST,
            collaborator: route.RIGHT_COLLABORATOR,
            manager: route.RIGHT_MANAGER,
          }
        })
    },
    // Define similar computed properties for toolsItems, clientItems, etc.
    familyOptions() {
      if (
        this.editedItem.idMaritalStatus === 3 ||
        this.editedItem.idMaritalStatus === 2 ||
        this.editedItem.idMaritalStatus === 4
      ) {
        return [
          {
            id: 1,
            designation: "Conjoint(e)",
          },
          {
            id: 2,
            designation: "Enfant",
          },
        ]
      } else {
        return [
          {
            id: 2,
            designation: "Enfant",
          },
        ]
      }
    },
  },
  watch: {
    // emailInput: {
    //   handler(newValue) {
    //     console.log(newValue)

    //     const emailList = [] // Créez une nouvelle liste pour stocker les adresses e-mail.

    //     newValue.forEach(element => {
    //       const matches = element.match(/<([^>]+)>/g) // Utilisez /g pour correspondre à toutes les occurrences dans la chaîne.
    //       if (matches) {
    //         matches.forEach(match => {
    //           const email = match.slice(1, -1) // Supprimez les chevrons autour de l'e-mail.
    //           emailList.push(email)
    //         })
    //       } else {
    //         emailList.push(element)
    //       }
    //     })

    //     this.emailList = emailList
    //   },
    // },
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
    rateValue() {
      this.$toast.info(`Merci pour votre note de ${this.rateValue}/5 ✭`, {
        position: "bottom-right",
        timeout: 3000,
      })
      this.rate = false
    },
    searchCityProfessionnal(val) {
      //verify if is number
      if (/^\d{5}$/.test(val)) {
        try {
          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItemsProfessionnal = res.map(item => item.nom)
              this.editedItem.mainWorkplaceZip = val
            })
            .catch(err => {
              console.log(err)
            })

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },
    searchCity(val) {
      //verify if is number
      if (/^\d{5}$/.test(val)) {
        try {
          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItems = res.map(item => item.nom)
              this.editedItem.zip = val
            })
            .catch(err => {
              console.log(err)
            })

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },
    searchEtablishment() {
      this.editedItem.mainWorkplaceStreet = this.agency.etablishment.find(
        element => element.id === this.searchEtablishment,
      ).street
      this.editedItem.mainWorkplaceCity = this.agency.etablishment.find(
        element => element.id === this.searchEtablishment,
      ).city
      this.searchCityProfessionnal = this.agency.etablishment.find(
        element => element.id === this.searchEtablishment,
      ).zip
    },
  },
  components: { Loading, CustomeLoader, apexchart: VueApexCharts, dialogControl },
  created() {
    if (!this.$store.state.authorizedRoutes.length) {
      this.$store.dispatch("fetchAuthorizedRoutes")
    }
    const controlledCookie = getCookie("controlled")

    if (!controlledCookie) {
      this.controlled = false
    }

    this.user = this.$store.state.user
    this.user.grade === "administrateur" ? (this.isAdmin = true) : (this.isAdmin = false)
    this.user.grade === "collaborateur" ? (this.isCollaborator = true) : (this.isCollaborator = false)
    this.user.grade === "guest" ? (this.isGuest = true) : (this.isGuest = false)
    this.user.grade === "manager" ? (this.isManager = true) : (this.isManager = false)
    this.user.grade === "superadministrateur" ? (this.isSuperAdmin = true) : (this.isSuperAdmin = false)

    this.servicesItems.forEach(element => {
      this.items.push(element.content)
    })
    this.toolsItems.forEach(element => {
      this.items.push(element.content)
    })

    this.fetchAccountCounter()

    this.setEditedItem()
    this.fetchAgency()
    this.fetchType()
    this.getRoutesBuiyable()
  },
  methods: {
    async deleteAdditionnalRoute(id) {
      const idAgency = this.$store.state.user.agency_id
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/routes/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: new Headers({
            Authorization: `Bearer ${getCookie("jwt")}`,
          }),
        })

        if (response.ok) {
          this.$toast.success("La route a été supprimée avec succès", {
            position: "bottom-right",
            timeout: 3000,
          })
        } else {
          this.$toast.error("Une erreur est survenue", {
            position: "bottom-right",
            timeout: 3000,
          })
        }

        //refresh the page
        location.reload()
      } catch (e) {
        console.log(e)
      }
    },
    async addAdditionnalRoute(idRoute) {
      const idAgency = this.$store.state.user.agency_id
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/routes`, {
          mode: "cors",
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${getCookie("jwt")}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({ idRoute }),
        })

        if (response.ok) {
          this.$toast.success("La route a été ajoutée avec succès", {
            position: "bottom-right",
            timeout: 3000,
          })
        } else {
          this.$toast.error("Une erreur est survenue", {
            position: "bottom-right",
            timeout: 3000,
          })
        }

        //refresh the page
        location.reload()
      } catch (e) {
        console.log(e)
      }
    },
    async getRoutesBuiyable() {
      const idAgency = this.$store.state.user.agency_id
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/routes/offers`, {
          mode: "cors",
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${getCookie("jwt")}`,
          }),
        })
        const data = await response.json()
        this.routesBuyable = data
      } catch (e) {
        console.log(e)
      }
    },
    checkRouteIDExist,
    testEmail() {
      const emailList = [] // Créez une nouvelle liste pour stocker les adresses e-mail.

      this.emailInput.forEach(element => {
        const matches = element.match(/<([^>]+)>/g) // Utilisez /g pour correspondre à toutes les occurrences dans la chaîne.
        if (matches) {
          matches.forEach(match => {
            const email = match.slice(1, -1) // Supprimez les chevrons autour de l'e-mail.
            emailList.push(email)
          })
        } else {
          emailList.push(element)
        }
      })

      this.emailList = emailList
      this.emailInput = this.emailList
    },
    async putAccountById() {
      const data = this.editedItem
      const formData = new FormData()
      formData.append("place", "putAccount")
      formData.append("id", data.id)
      formData.append("lastname", data.lastname)
      formData.append("firstname", data.firstname)
      formData.append("idState", data.grade)
      formData.append("email", data.email)
      formData.append("phoneNumber", data.phoneNumberProfessional)
      formData.append("idServiceManager", data.idServiceManager)
      formData.append("idTypeContract", data.idTypeContract)
      formData.append("idTypeJob", data.idTypeJob)
      formData.append("idTypeEmployment", data.idTypeEmployment)
      formData.append("idTypeQualification", data.idTypeQualification)
      formData.append("entryDate", data.entryDate)
      formData.append("idService", data.idService)
      formData.append("street", data.street)
      formData.append("city", data.city)
      formData.append("zip", data.zip)
      formData.append("idMaritalStatus", data.idMaritalStatus)
      formData.append("mainWorkplaceZip", data.mainWorkplaceZip)
      formData.append("mainWorkplaceCity", data.mainWorkplaceCity)
      formData.append("mainWorkplaceStreet", data.mainWorkplaceStreet)
      formData.append("personnalPhoneNumber", data.personnalPhoneNumber)
      formData.append("personnalEmail", data.personnalEmail)
      formData.append("idCollectiveAgreement", data.idCollectiveAgreement)

      data.familyMembers.length > 0 ? formData.append("familyMembers", JSON.stringify(data.familyMembers)) : null

      try {
        const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        })
        this.$toast.success(
          `Vous venez de modifier le compte ${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} avec succès.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )

        this.stepperAccountTutorial++
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async sendInvitationMail() {
      async function postGuestAccount(email) {
        try {
          const formData = new FormData()
          formData.append("place", "addGuestAccount")
          formData.append("idMasterAccount", localStorage.getItem("master_account_linked"))
          formData.append("idAgency", this.$store.state.user.agency_id)
          formData.append("email", email)

          const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
            mode: "cors",
            method: "POST",
            body: formData,
          })

          let data = await response.json()

          if (data.code === 3) {
            return { idAccount: data.id_account, token: data.token }
          } else {
            throw new Error("Une erreur est survenue")
          }
        } catch (e) {
          console.log(e)
        }
      }

      // Utilisez une fonction itérative asynchrone pour introduire un délai entre chaque envoi d'e-mail
      async function sendEmailWithDelay(email) {
        try {
          let { idAccount, token } = await postGuestAccount(email)

          await emailjs.send("service_5p49t7p", "template_hkv4wtz", {
            to_email: email,
            link: `${config.appUri}/auth/login?firstconnection=1&id=${idAccount}&token=${token}&type=guest`,
          })
        } catch (error) {
          console.log(error)
        }
      }

      for (const collaborator of this.emailList) {
        // Introduisez un délai de 4 secondes (4000 millisecondes) entre chaque envoi d'e-mail
        this.emailLoading = true
        await new Promise(resolve => setTimeout(resolve, 1000))

        await sendEmailWithDelay(collaborator).then(() => {
          this.emailLoading = false
        })
      }
    },
    // handleEmailInput() {
    //   // Assurez-vous que emailInput est une chaîne de caractères
    //   let inputString = Array.isArray(this.emailInput) ? this.emailInput.join("; ") : this.emailInput

    //   // Expression régulière pour extraire les adresses email
    //   const emailRegex = /[\w.-]+@[\w.-]+\.\w+/g
    //   const matches = inputString.match(emailRegex)

    //   if (matches) {
    //     // Mettre à jour le tableau emailList avec les adresses email trouvées
    //     this.emailList = matches
    //   }
    // },

    extractEmail(text) {
      const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g
      return text.split(";").filter(email => emailPattern.test(email.trim()))
    },

    addEmail(email) {
      console.log(this.validateEmail(email))
      if (this.validateEmail(email) && !this.emails.includes(email)) {
        this.emails.push(email)
      }
    },
    validateEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    async fetchType() {
      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      headers.append("Authorization", `Bearer ${this.jwt}`)

      await pause(1500)

      try {
        let response = await fetch(`${config.apiUri}/contracts`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        if (response.ok) {
          data.type_employments.forEach(employment => {
            this.typeEmployments.push(employment)
          })
          data.type_contracts.forEach(contract => {
            this.typeContracts.push(contract)
          })
          data.type_jobs.forEach(job => {
            this.typeJobs.push(job)
          })
          data.type_qualifications.forEach(qualification => {
            this.typeQualifications.push(qualification)
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    addLine(array) {
      array.push({
        maritalStatus: null,
        firstname: null,
        lastname: null,
        createClient: true,
      })

      this.$nextTick(() => {
        const inputElement = document.getElementById("input-family-" + (array.length - 1))
        if (inputElement) {
          inputElement.focus()
        }
      })
    },
    async fetchAccountCounter() {
      this.account = {}

      let headers = new Headers()
      let idAccount = this.$store.state.user.id

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      headers.append("Authorization", `Bearer ${this.jwt}`)

      try {
        let response = await fetch(`${config.apiUri}/accounts/${idAccount}/counter`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (response.ok) {
          this.account = data

          if (this.account.next_holiday) {
            this.account.next_holiday = {
              start: moment(this.account.next_holiday.start_date).format("DD/MM/YYYY"),
              end: moment(this.account.next_holiday.end_date).format("DD/MM/YYYY"),
            }
          }

          this.contactNumber = this.account.contact_count
        }
        // this.updateChartData(this.statistics)
      } catch (e) {
        console.log(e)
      }
    },
    async updateChartData(weeklyRequests) {
      // Préparer les données pour les séries du graphique
      const dataDemandesEnCours = weeklyRequests.map(item => ({
        x: moment(item.request_date).format("Do MMM"), // La date de la demande
        y: item.request_count_contact, // Le nombre de demandes en cours pour cette date
      }))

      const dataDemandesTraitees = weeklyRequests.map(item => ({
        x: moment(item.request_date).format("Do MMM"), // La date de la demande
        y: item.request_count_contact_history, // Le nombre de demandes traitées pour cette date
      }))

      // Mettre à jour les séries de données pour le graphique
      this.series = [
        {
          name: "Demandes en cours",
          data: dataDemandesEnCours,
          color: "#FF4560", // Rouge, par exemple
        },
        {
          name: "Demandes traitées",
          data: dataDemandesTraitees,
          color: "#00E396", // Vert, par exemple
        },
      ]
    },
    async fetchAgency() {
      this.agency = []

      let headers = new Headers()
      let agencyId = this.$store.state.user.agency_id

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      headers.append("Authorization", `Bearer ${this.jwt}`)

      try {
        let response = await fetch(`${config.apiUri}/agencies/${agencyId}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (response.ok) {
          this.agency = data.agency
        }
      } catch (e) {
        console.log(e)
      }
    },
    save(idUser) {
      // this.put(idUser, {
      //   lastname: this.editedItem.lastname,
      //   firstname: this.editedItem.firstname,
      //   grade: this.editedItem.statut,
      //   email: this.editedItem.email,
      //   phoneNumber: this.editedItem.phoneNumber,
      //   idServiceManager: this.editedItem.serviceChoose,
      //   idTypeContract: this.editedItem.idTypeContract,
      //   idTypeJob: this.editedItem.idTypeJob,
      //   idTypeEmployment: this.editedItem.idTypeEmployment,
      //   idTypeQualification: this.editedItem.idTypeQualification,
      //   familyMembers: this.editedItem.familyMembers,
      //   entryDate: this.editedItem.entryDate,
      //   idService: this.editedItem.idService,
      //   street: this.editedItem.street,
      //   city: this.editedItem.city,
      //   zip: this.editedItem.zip,
      //   idMaritalStatus: this.editedItem.idMaritalStatus,
      // })
      this.stepperAccountTutorial++
      this.stepperTutorial++
    },

    async putAccount(idUser, data) {
      const formData = new FormData()
      formData.append("place", "putAccount")
      formData.append("id", idUser)
      formData.append("lastname", data.lastname)
      formData.append("firstname", data.firstname)
      formData.append("idState", data.idState)
      formData.append("email", data.email)
      formData.append("phoneNumber", data.phoneNumber)
      formData.append("idServiceManager", data.idServiceManager)
      formData.append("idTypeContract", data.idTypeContract)
      formData.append("idTypeJob", data.idTypeJob)
      formData.append("idTypeEmployment", data.idTypeEmployment)
      formData.append("idTypeQualification", data.idTypeQualification)
      formData.append("entryDate", data.entryDate)
      formData.append("idService", data.idService)
      formData.append("street", data.street)
      formData.append("city", data.city)
      formData.append("zip", data.zip)
      formData.append("idMaritalStatus", data.idMaritalStatus)

      data.familyMembers.length > 0 ? formData.append("familyMembers", JSON.stringify(data.familyMembers)) : null

      try {
        const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        })
        this.$toast.info(
          `Vous venez de modifier le compte ${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} avec succès.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.stepperAccountTutorial = 0
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    setEditedItem() {
      this.editedItem = {
        id: localStorage.getItem("user_id"),
        firstname: localStorage.getItem("firstname"),
        lastname: localStorage.getItem("lastname"),
        lastConnection: "",
        lastIPConnected: "",
        statut: localStorage.getItem("grade"),
        email: localStorage.getItem("email"),
        phoneNumberProfessional: localStorage.getItem("phone"),
        idTypeContract: null,
        idTypeEmployment: null,
        idTypeJob: null,
        idTypeQualification: null,
        familyMembers: [],
        idMaritalStatus: 1,
      }
    },

    appointmentRoute() {
      this.$router.push("/services/appointment?vtab=tab-2")
    },
    // async fetchAttributedAppointments(idCollaborator) {
    //   let now = moment().format("YYYY-MM-DD")
    //   let anteriatory = false
    //   let time = 0
    //   this.appointmentsAttributed = []
    //   this.waitTimeoutAppointmentsAttributed = true

    //   let headers = new Headers()
    //   headers.append("Content-Type", "application/json charset=UTF-8")
    //   headers.append("Accept", "application/json")
    //   headers.append("Origin", "*")

    //   await pause(1500)

    //   try {
    //     let headers = new Headers()

    //     headers.append("Content-Type", "application/json")

    //     const response = await fetch(`${config.apiUri}/accounts/${idCollaborator}/appointment`, {
    //       mode: "cors",
    //       method: "GET",
    //       headers: headers,
    //     })

    //     const data = await response.json()

    //     data.forEach(el => {
    //       anteriatory = false
    //       moment(now).isAfter(el.date_start) ? (anteriatory = true) : (anteriatory = false)

    //       anteriatory
    //         ? (time = moment(now).diff(moment(el.date_start).format("YYYY-MM-DD"), "days"))
    //         : (time = moment(el.date_start).diff(moment(now).format("YYYY-MM-DD"), "days"))

    //       this.appointmentsAttributed.push({
    //         id: el.id,
    //         date: moment(el.date_start).format("dddd Do MMMM YYYY"),
    //         hourStart: moment(el.date_start).format("HH:mm"),
    //         hourEnd: moment(el.date_end).format("HH:mm"),
    //         lastname: el.lastname,
    //         firstname: el.firstname,
    //         appointment_type: el.appointment_type,
    //         agency: el.agency,
    //         contracts: el.contracts,
    //         anteriatory: anteriatory,
    //         time: time,
    //         date_start: el.date_start,
    //       })
    //     })
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },

    verifyLength(module) {
      let result = false
      if (this.$store.state.isAuthenticated) {
        switch (this.$store.state.user.grade) {
          case "superadministateur":
            result = module.some(item => item.superadmin === true)
            return result
          case "administrateur":
            result = module.some(item => item.admin === true)
            return result
          case "collaborateur":
            result = module.some(item => item.collaborator === true)
            return result
          case "manager":
            result = module.some(item => item.manager === true)
            return result
          case "guest":
            result = module.some(item => item.guest === true)
            return result
        }
      }
      return result
    },
    // async getContactNumber() {
    //   this.waitTimeoutContact = true

    //   await pause(1500)

    //   try {
    //     let headers = new Headers()
    //     let userId = localStorage.getItem("user_id")

    //     headers.append("Content-Type", "application/json")
    //     headers.append("Accept", "application/json")
    //     headers.append("Origin", "*")

    //     const response = await fetch(`${config.apiUri}/php/pages/client_contact.php?idAccount=${userId}&count=true`, {
    //       mode: "cors",
    //       method: "GET",
    //       headers: headers,
    //     })

    //     const data = await response.json()
    //     this.contactNumber = data.contact_number

    //     this.waitTimeoutContact = false
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },

    // async getAppointmentNumber() {
    //   this.waitTimeoutContact = true

    //   await pause(1500)

    //   try {
    //     let headers = new Headers()
    //     const idAgency = this.$store.state.user.agency_id

    //     headers.append("Content-Type", "application/json")
    //     headers.append("Accept", "application/json")
    //     headers.append("Origin", "*")

    //     const response = await fetch(`${config.apiUri}/agencies/${idAgency}/appointment/number`, {
    //       mode: "cors",
    //       method: "GET",
    //       headers: headers,
    //     })

    //     const data = await response.json()
    //     this.appointmentNumber = data.appointment_number

    //     this.waitTimeoutContact = false
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    download() {
      this.$toast.error(
        "L'application Mes Services Assurance n'est pas encore disponible sous forme d'application.\nNous vous tiendrons informé dès lors qu'elle sera déployée",
        { position: "bottom-right", timeout: 3000 },
      )
    },
    querySelections(v) {
      this.loading = true
      this.isSearch = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter(e => {
          return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
        })
        this.loading = false
      }, 500)
    },
  },
  mounted() {},
  beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
/*---------------------------------------- */
.btn-add-module {
  background-color: #001f47;
  color: white;

  transition: all 0.3s ease-in-out;
  margin-top: -5px !important;
}
.hover-card-dashboard:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: scale(1.08);
}

// if hover is leave

.test-responsive {
  width: 100% !important;
}

.mullerFont {
  font-family: muller;
}

.title-text {
  font-family: muller;

  font-size: 1.25rem;
}

.v-card--reveal {
  background-color: #001f47;
  color: white;
  opacity: 0.9;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  width: 100%;
  border-radius: 20px;
}

.v-card--reveal-true {
  background-color: #001f47;
  color: white;
  opacity: 1;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  width: 100%;
  border-radius: 20px;
}

.services-font {
  font-size: 10px;
}

.services {
  //background-color: #001f47;
  background-size: cover;
  padding: 5.5rem;
  margin-bottom: 20px;
}

.test {
  //background-color: #001f47;
  background-size: cover;
  padding: 3rem;
  color: white !important;

  .text-h4 {
    color: white !important;
    font-weight: 800;
  }
}

.carousel {
  color: white !important;

  .text-h4 {
    color: white !important;
    font-weight: 800;
  }
}

.title {
  font-size: 10rem !important;
}

@media (max-width: 600px) {
  .services {
    padding: 1.5rem !important;
  }
}

.greeting-card {
  position: relative;

  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }

    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }

  .disableCursor:hover {
    cursor: not-allowed;
  }
}

.card-title {
  line-height: 0.8rem !important;
  letter-spacing: 0 !important;
}
</style>
